import axios from 'lib/axios'

export const useExpenses = () => {
  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const putExpense = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    axios
      .put(`/api/v1/expenses/${props.dataItem.id}`, props.dataItem)
      .then(status => {
        setStatus(status)
        //mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const postExpense = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/expenses', props.dataItem)
      .then(status => {
        setStatus(status)
        //mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const getExpenses = async ({ query }) => {
    await csrf()
    return axios
      .get(`/api/v1/expenses?includeIssue=true${query ? query : ''}`)
      .then(res => {
        var parsedData = res.data.data.map(expenses => {
          expenses.date = expenses.date ? new Date(expenses.date) : null
          return expenses
        })
        return { data: parsedData }
      })
      .catch(error => error.response)
  }

  return {
    putExpense,
    postExpense,
    getExpenses
  }
}
