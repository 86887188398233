import axios from 'lib/axios'
import useSWR from 'swr'

export const useOrders = query => {
  const csrf = () => axios.get('/sanctum/csrf-cookie')
  const {
    data: ordersHook,
    error,
    mutate
  } = useSWR(`/api/v1/orders?${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/orders?${query ? query : ''}`)
      .then(res => res.data)
      .catch(error => error.response)
  )

  const getOrders = async ({ query }) => {
    await csrf()
    return axios
      .get(`/api/v1/orders?${query ? query : ''}`)
      .then(res => {
        var parsedData = res.data.data.map(timeRecord => {
          timeRecord.date = timeRecord.date ? new Date(timeRecord.date) : null
          timeRecord.startTime = timeRecord.startTime
            ? new Date(timeRecord.startTime)
            : null
          timeRecord.endTime = timeRecord.endTime
            ? new Date(timeRecord.endTime)
            : null
          return timeRecord
        })
        return { data: parsedData }
      })
      .catch(error => error.response)
  }

  const postOrder = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/orders', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const putOrder = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    axios
      .put(`/api/v1/orders/${props.dataItem.id}`, props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  return {
    ordersHook,
    getOrders,
    postOrder,
    putOrder
  }
}
