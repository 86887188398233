import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import { useEvents } from 'hooks/events'
import IssuesDataGrid from 'components/pages/crm/issues/IssuesDataGrid/Index'
import { useIssues } from 'hooks/issues'
import { useEffect, useState } from 'react'
import SponsoringsDataGrid from 'components/pages/crm/sponsorings/SponsoringsDataGrid/Index'
import { useSponsorings } from 'hooks/sponsorings'
import ExportTimesExpenses from 'components/pages/exports/crm/ExportTimesExpenses/Index'
import { useTimeRecords } from 'hooks/timeRecords'
import { useExpenses } from 'hooks/expenses'
import { useSponsoringBenefits } from 'hooks/sponsoringBenefits'
import ExportIssues from 'components/pages/exports/crm/ExportIssues/Index'
import ExportSponsorings from 'components/pages/exports/crm/ExportSponsorings/Index'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useUsers } from 'hooks/user'
import { useCompanies } from 'hooks/companies'
import { useCompanyContacts } from 'hooks/companyContacts'
import ExportSponsorsOnSite from 'components/pages/exports/crm/ExportSponsorsOnSite/Index'

const EventDetails = () => {
  const params = useParams()
  const { t } = useTranslation('crm')
  document.title = 'Event Details'
  const { eventsHook, getEvent } = useEvents({
    query: `?includeProject=true&includeDashboard=true&includeIssues=true&order_by=-start_date&id[eq]=${params.eventId}`
  })
  const { usersHook } = useUsers({
    query: '?is_active[eq]=1&order_by=id'
  })
  const { eventsHook: eventsHookIssue } = useEvents({
    query: '?order_by=-name&includeProject=true'
  })
  const [event, setEvent] = useState(null)
  const [loading, setLoading] = useState(false)
  const { getTimeRecords } = useTimeRecords("")
  const { getExpenses } = useExpenses()
  const [deadlineIssues, setDeadlineIssues] = useState([])
  const { sponsoringsHook, putSponsoring, postSponsoring, deleteSponsoring } =
    useSponsorings({
      query: `?includeSponsoringBenefits=true&event_id[eq]=${params.eventId}`
    })
  const { companiesHook } = useCompanies({
    query: '?includeCompanyContacts=true&order_by=name'
  })
  const { companyContactsHook } = useCompanyContacts({
    query: '?order_by=lastname'
  })
  const { sponsoringBenefitsHook } = useSponsoringBenefits({
    query: '?order_by=seq'
  })
  const { issuesHook, putHook, postHook, deleteHook, patchHook } = useIssues({
    query: `?includeProject=true&event_id[eq]=${params.eventId}`
  })
  const [selected, setSelected] = useState(0)
  const handleSelect = e => {
    setSelected(e.selected)
  }
  const handleChange = ev => {
    setLoading(true)
    setEvent(ev.value)
  }

  useEffect(() => {
    if (eventsHook) {
      setEvent(eventsHook?.data[0])
    }
  }, [eventsHook])

  useEffect(() => {
    if (issuesHook) {
      setDeadlineIssues(
        issuesHook.data
          .filter(issue => issue.priority == 3)
          .sort((a, b) => a.state - b.state)
      )
      getEvent({ eventId: params.eventId, setEvent: setEvent })
      setLoading(false)
    }
  }, [issuesHook])

  useEffect(() => {
    if (sponsoringsHook && event?.id) {
      getEvent({ eventId: params.eventId, setEvent: setEvent })
    }
  }, [sponsoringsHook])

  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Event Dashboard</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className="flex flex-col">
              {event?.id && (
                <Grid xs={2} lg={3} xl={3} className="flex-1">
                  <Paper className="px-2 py-2 h-full flex flex-col justify-center">
                    <p className="text-xl align-center text-center font-bold">
                      {event.name}
                    </p>
                  </Paper>
                </Grid>
              )}
              {event?.id && (
                <Grid xs={2} lg={2} xl={2} className="flex-1">
                  <Paper className="px-2 py-2 h-full">
                    <p>Sponsorings:</p>{' '}
                    <p className="text-xl align-center text-center font-bold">
                      {event.totalSponsoringAmount.toFixed(0)} €
                    </p>
                  </Paper>
                </Grid>
              )}
              {event?.id && (
                <Grid xs={2} lg={2} xl={2}>
                  <Paper className="px-2 py-2 h-full">
                    <p>Total time:</p>{' '}
                    <p className="text-xl align-center text-center font-bold">
                      {(event.totalTime / 60).toFixed(1)} h
                    </p>
                  </Paper>
                </Grid>
              )}
              {event?.id && (
                <Grid xs={2} lg={2} xl={2}>
                  <Paper className="px-2 py-2 h-full">
                    <p>Total expenses:</p>{' '}
                    <p className="text-xl align-center text-center font-bold">
                      {event.totalExpenses.toFixed(2)} €
                    </p>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Box>

          {event?.id && (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                filter: loading ? 'blur(1rem)' : 'blur(0)',
                disabled: true
              }}>
              <Grid container spacing={2}>
                <Grid xs={12} lg={12} xl={12}>
                  <Paper className="px-2 py-2">
                    <TabStrip selected={selected} onSelect={handleSelect}>
                      <TabStripTab
                        title="Deadlines"
                        contentClassName="k-animation-container-w-full">
                        <table className="w-full text-lg">
                          {deadlineIssues.map(value => (
                            <tr className="flex w-full mb-2">
                              <td
                                className="w-10 text-center align-top"
                                title={value.user.name}>
                                {value.user.name.match(/\b(\w)/g).join('')}
                              </td>
                              <td className="w-36 text-center align-top">
                                {Math.ceil(
                                  (value.dueDate - new Date()) /
                                    (1000 * 60 * 60 * 24)
                                )}{' '}
                                Days left
                              </td>
                              <td className="w-32 text-center align-top">
                                {getDateFormated(value.dueDate)}
                              </td>
                              <td className="w-32 text-center align-top ">
                                <span
                                  className="rounded-full block"
                                  style={{
                                    backgroundColor:
                                      value.state != 2
                                        ? 'rgb(243, 23, 0, 0.32)'
                                        : 'rgb(127,255, 0, 0.32)'
                                  }}>
                                  {t(`issues.state.${value.state}`)}
                                </span>
                              </td>
                              <td className="ml-2 flex-1 ">{value.label}</td>
                            </tr>
                          ))}
                        </table>
                      </TabStripTab>
                      <TabStripTab title="Issues">
                        <IssuesDataGrid
                          itemHook={issuesHook}
                          putHook={putHook}
                          postHook={postHook}
                          deleteHook={deleteHook}
                          patchHook={patchHook}
                          usersHook={usersHook?.data ?? []}
                          eventsHook={eventsHookIssue?.data ?? []}
                          duplicate={true}
                          initialFilter={{
                            filters: [
                              {
                                filters: [
                                  {
                                    field: 'state',
                                    operator: 'eq',
                                    value: '0'
                                  },
                                  {
                                    field: 'state',
                                    operator: 'eq',
                                    value: '1'
                                  },
                                  {
                                    field: 'state',
                                    operator: 'eq',
                                    value: '3'
                                  }
                                ],
                                logic: 'or'
                              }
                            ],
                            logic: 'and'
                          }}
                        />
                      </TabStripTab>
                      <TabStripTab title="Sponsorings">
                        <SponsoringsDataGrid
                          itemHook={sponsoringsHook}
                          putHook={putSponsoring}
                          postHook={postSponsoring}
                          deleteHook={deleteSponsoring}
                          sponsoringBenefitsHook={
                            sponsoringBenefitsHook?.data ?? []
                          }
                          eventsHook={eventsHookIssue?.data ?? []}
                          companiesHook={companiesHook?.data ?? []}
                          companyContactsHook={companyContactsHook?.data ?? []}
                        />
                      </TabStripTab>
                      <TabStripTab title="Exports">
                        <ul>
                          <li>
                            <ExportTimesExpenses
                              exportData={issuesHook?.data}
                              getTimeRecords={getTimeRecords}
                              getExpenses={getExpenses}
                              title="Zeiterfassung"
                              eventId={event.id}
                            />
                          </li>
                          <li>
                            <ExportIssues
                              exportData={issuesHook?.data}
                              title="Issues"
                            />
                          </li>
                          <li>
                            <ExportSponsorings
                              exportData={sponsoringsHook?.data}
                              pivotData={sponsoringBenefitsHook?.data}
                              title="Sponsorings"
                            />
                          </li>
                          <li>
                            <ExportSponsorsOnSite
                              exportData={sponsoringsHook?.data}
                              pivotData={sponsoringBenefitsHook?.data}
                              title="Sponsors on site"
                            />
                          </li>
                        </ul>
                      </TabStripTab>
                    </TabStrip>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default EventDetails

const getDateFormated = date =>
  `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
    -2
  )}.${date.getFullYear()}`
