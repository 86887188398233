import useSWR from 'swr'
import axios from 'lib/axios'
import { useEffect } from 'react'

export const useSponsoringBenefits = ({ query }) => {
  const {
    data: sponsoringBenefitsHook,
    error,
    mutate
  } = useSWR(`/api/v1/sponsoring-benefits${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/sponsoring-benefits${query ? query : ''}`)
      .then(res => res.data)
      .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const getSponsoringBenefits = async ({ query }) => {
    await csrf()
    return axios
      .get(`/api/v1/sponsoring-benefits${query ? query : ''}`)
      .then(res => res.data)
      .catch(error => error.response)
  }

  useEffect(() => {}, [sponsoringBenefitsHook, error])

  return {
    sponsoringBenefitsHook,
    getSponsoringBenefits
  }
}
