import { Button } from '@mui/material'
import * as XLSX from 'xlsx'
import { getTimeFormated } from 'lib/convinience'

const ExportTimesExpenses = props => {
  const { getTimeRecords, getExpenses, title, eventId, exportData } = props

  const handleClick = async () => {
    var data = []
    var timeRecs = []
    var expenses = []
    if (exportData) {
      data = exportData.map(issue => {
        timeRecs = [
          ...timeRecs,
          ...issue.timeRecords.map(tr => ({ ...tr, issue: issue }))
        ]
        expenses = [
          ...expenses,
          ...issue.expenses.map(e => ({ ...e, issue: issue }))
        ]
      })
      data = parseData(timeRecs).concat(parseData(expenses, true))
    } else {
      timeRecs = await getTimeRecords({
        query: eventId ? `&issue.id[eq]=${eventId}` : ''
      })
      expenses = await getExpenses({
        query: eventId ? `&issue.id[eq]=${eventId}` : ''
      })
      data = parseData(timeRecs.data).concat(parseData(expenses.data, true))
    }
    downloadExcel(data)
  }
  return <Button onClick={handleClick}>{title}</Button>
}

export default ExportTimesExpenses

const downloadExcel = data => {
  const worksheet = XLSX.utils.json_to_sheet(data)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Zeiterfassung')
  XLSX.writeFile(workbook, 'Zeiterfassung.xlsx')
}

const parseData = (data, expense = false) =>
  data.map(timeRec => ({
    ID: expense ? `X${timeRec.id}` : `T${timeRec.id}`,
    Project: timeRec.issue.event.name,
    User: timeRec.user.name,
    Datum: timeRec.date,
    Start1: expense ? null : getTimeFormated(timeRec.startTime),
    Ende1: expense ? null : getTimeFormated(timeRec.endTime),
    Dauer: timeRec.workedTime,
    Erläuterung: timeRec.invoiceText,
    Auslage: expense ? timeRec.amount : '',
    Kategorie_verkettet: timeRec.issue.issueSubcategory?.label
      ? `${timeRec.issue.issueCategory?.label}_${timeRec.issue.issueSubcategory?.label}`
      : '',
    Leistungsgruppe: timeRec.issue.issueCategory?.label,
    Dienstleistung: timeRec.issue.issueSubcategory?.label,
    Zusatzaufwand: expense ? '': timeRec?.checkRelevant,
    Rechnung: '',
    AufgabenTitel: timeRec.issue?.label,
    AufgabenBeschreibung: timeRec.issue?.remark,
    Bemerkung: timeRec.remark,
  }))
