import axios from 'lib/axios'
import useSWR from 'swr'
import { useEffect } from 'react'

export const useTimeRecords = query => {
  
  const {
    data: timerecordsHook,
    error,
    mutate
  } = useSWR(
    `/api/v1/timerecords?includeIssue=true${query ? query : ''}`,
    () =>
      axios
        .get(`/api/v1/timerecords?includeIssue=true${query ? query : ''}`)
        .then(res => {
          var parsedData = res.data.data.map(timerecord => {
            timerecord.date = timerecord.date ? new Date(timerecord.date) : null
            timerecord.startTime = timerecord.startTime
              ? new Date(timerecord.startTime)
              : null
            timerecord.endTime = timerecord.endTime
              ? new Date(timerecord.endTime)
              : null
            return timerecord
          })
          return { data: parsedData }
        })
        .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')
  
  const getTimeRecords = async ({ query }) => {
    await csrf()

    return axios
      .get(`/api/v1/timerecords?includeIssue=true${query ? query : ''}`)
      .then(res => {
        var parsedData = res.data.data.map(timeRecord => {
          timeRecord.date = timeRecord.date ? new Date(timeRecord.date) : null
          timeRecord.startTime = timeRecord.startTime
            ? new Date(timeRecord.startTime)
            : null
          timeRecord.endTime = timeRecord.endTime
            ? new Date(timeRecord.endTime)
            : null
          return timeRecord
        })
        return { data: parsedData }
      })
      .catch(error => error.response)
  }

  useEffect(() => {}, [timerecordsHook, error])

  return {
    timerecordsHook,
    getTimeRecords
  }
}
