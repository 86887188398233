import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import { useTimeRecords } from 'hooks/timeRecords'
import { useExpenses } from 'hooks/expenses'
import { useSponsorings } from 'hooks/sponsorings'
import { useSponsoringBenefits } from 'hooks/sponsoringBenefits'
import { useCompanyContacts } from 'hooks/companyContacts'
import { useEvents } from 'hooks/events'
import ExportTimesExpenses from 'components/pages/exports/crm/ExportTimesExpenses/Index'
import ExportSponsorings from 'components/pages/exports/crm/ExportSponsorings/Index'
import ExportCompanyContacts from 'components/pages/exports/crm/ExportCompanyContacts/Index'
import ExportEvents from 'components/pages/exports/crm/ExportEvents/Index'
import ExportTimeTracking from 'components/pages/exports/crm/ExportTimeTracking/Index'
import ExportVimeoVideos from 'components/pages/exports/crm/ExportVimeoVideos/Index'
import { useIssues } from 'hooks/issues'
import { useVimeoExportData } from 'hooks/vimeoExport'

const ExporteCRM = () => {
  const { getTimeRecords } = useTimeRecords("")
  const { getExpenses } = useExpenses()
  const { getEvents } = useEvents({ query: '' })
  const {getVimeoExportData} = useVimeoExportData({ query: '/1' })
  const { getSponsorings } = useSponsorings({
    query: '?includeSponsoringBenefits=true'
  })
  const { getSponsoringBenefits } = useSponsoringBenefits({
    query: '?order_by=seq'
  })
  const { getCompanyContacts } = useCompanyContacts({
    query: '?includeCompany=true'
  })
  const { getIssues } = useIssues({
    query: ''
  })
  document.title = 'Export CRM'

  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Exports</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="px-2 py-2">
                  <ul class="max-w-md space-y-1 text-gray-500 list-none list-inside dark:text-gray-400">
                    <li>
                      <ExportEvents getEvents={getEvents} title="Events" />
                    </li>
                    <li>
                      <ExportTimeTracking getIssues={getIssues} title="Events Zeit Übersicht" />
                    </li>
                    <li>
                      <ExportSponsorings
                        getSponsorings={getSponsorings}
                        getSponsoringBenefits={getSponsoringBenefits}
                        title="Sponsorings"
                      />
                    </li>
                    <li>
                      <ExportCompanyContacts
                        getCompanyContacts={getCompanyContacts}
                        title="Company Contacts"
                      />
                    </li>
                    <li>
                      <ExportTimesExpenses
                        getTimeRecords={getTimeRecords}
                        getExpenses={getExpenses}
                        title="Zeiterfassung"
                      />
                    </li>
                    <li>
                      <ExportVimeoVideos
                        getVimeoExportData={getVimeoExportData}
                        title="Vimeo Videos"
                      />
                    </li>
                  </ul>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default ExporteCRM
