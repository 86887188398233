import useSWR from 'swr'
import axios from 'lib/axios'
import { useEffect } from 'react'

export const useSponsorings = ({ query }) => {
  const {
    data: sponsoringsHook,
    error,
    mutate
  } = useSWR(`/api/v1/sponsorings${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/sponsorings${query ? query : ''}`)
      .then(res => {
        var parsedData = res.data.data.map(sponsoring => {
          sponsoring.sponsoringBenefitss = sponsoring.sponsoringBenefits.reduce(
            (acc, cur, i) => {
              acc[cur.id] = cur.remark == '1' ? true : cur.remark
              return acc
            },
            {}
          )
          return sponsoring
        })
        return { data: parsedData }
      })
      .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const getSponsorings = async ({ query }) => {
    await csrf()
    return axios
      .get(
        `/api/v1/sponsorings?includeSponsoringBenefits=true${
          query ? query : ''
        }`
      )
      .then(res => res.data)
      .catch(error => error.response)
  }

  const putSponsoring = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    axios
      .put(`/api/v1/sponsorings/${props.dataItem.id}`, props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const postSponsoring = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/sponsorings', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const deleteSponsoring = async ({ setStatus, ...props }) => {
    await csrf()

    setStatus(null)

    axios.delete(`/api/v1/sponsorings/${props.dataItem.id}`).then(status => {
      setStatus(status)
      mutate()
    })
  }

  useEffect(() => {}, [sponsoringsHook, error])

  return {
    sponsoringsHook,
    getSponsorings,
    putSponsoring,
    postSponsoring,
    deleteSponsoring
  }
}
